@import (reference) '../theme';
@import (reference) '../../mixins';
@import (reference) 'buttons';

.odd(@val) {
    @result: if(mod(@val, 2), @val, @val - 1);
}

// Toggle button
@aui-toggle-border-radius: @aui-toggle-height;
@aui-toggle-border-style: @aui-button-border-style;
@aui-toggle-border-width: 2px;
@aui-toggle-horizontal-padding: 8px;
@aui-toggle-vertical-padding: 6px;
@aui-toggle-icon-size: 16px;
@aui-toggle-font-size: @aui-font-size-small;
@aui-toggle-width: (@aui-toggle-border-width * 2 + @aui-toggle-horizontal-padding * 3 + @aui-toggle-icon-size * 2);

@aui-toggle-effective-height: 26px;
@aui-toggle-height: unit(@aui-toggle-effective-height / @aui-toggle-font-size, em); // note: this is the same as a compact AUI button.
@aui-toggle-icon-top: unit((@aui-toggle-effective-height - @aui-toggle-icon-size) / 2, px);
@aui-toggle-handle-width: .odd(@aui-toggle-icon-size + @aui-toggle-horizontal-padding)[@result]; // circles work better with odd numbers
@aui-toggle-slide-width: (@aui-toggle-icon-size * 2);

@aui-toggle-default-bg-color: var(--aui-toggle-default-bg-color);
@aui-toggle-default-bg-hover-color: var(--aui-toggle-default-bg-hover-color);
@aui-toggle-button-color: var(--aui-toggle-button-color);
@aui-toggle-on-color: var(--aui-toggle-on-color);
@aui-toggle-on-hover-color: var(--aui-toggle-on-hover-color);
@aui-toggle-disabled-overlay-color: var(--aui-toggle-disabled-overlay-color);
@aui-toggle-tick-color: var(--aui-toggle-tick-color);
@aui-toggle-cross-color: var(--aui-toggle-cross-color);

@aui-toggle-animation-slide-delay: 150ms;
@aui-toggle-animation-background-color-delay: 100ms;
@aui-toggle-animation-visible-delay: 250ms;
