@import (reference) './imports/global';

.aui-tooltip {
    background-color: var(--aui-tooltip-bg-color);
    padding: 2px 6px;
    pointer-events: none;
    z-index: @aui-z-tooltip;
    border-radius: @aui-border-radius-small;
    font-size: @aui-font-size-small;
    line-height:  unit(20 / @aui-font-size-small);
    word-wrap: break-word;

    a,
    a:visited {
        color: inherit;
        text-decoration: underline;
    }

    .aui-tooltip-content {
        color: var(--aui-tooltip-content-text-color);
        margin: 0;
    }

    .aui-tooltip-title {
        color: var(--aui-tooltip-title-text-color);
        font-weight: bold;
        margin: 0;
    }
}
